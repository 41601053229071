<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.7717 5.29162C21.63 5.20768 21.4549 5.20441 21.3103 5.28324L16.2626 8.03943V6.38568C16.2611 5.09917 15.2186 4.05663 13.9321 4.05518H2.33051C1.04399 4.05663 0.00145657 5.09917 0 6.38568V15.5875C0.00145657 16.8741 1.04399 17.9166 2.33051 17.9181H13.9321C15.2186 17.9166 16.2611 16.8741 16.2626 15.5875V13.9638L21.3105 16.72C21.4549 16.7989 21.6302 16.7958 21.7717 16.7118C21.9133 16.6277 22 16.4753 22 16.3109V5.69236C22 5.52777 21.9132 5.37555 21.7717 5.29162ZM15.33 15.5877C15.3293 16.3597 14.7037 16.9851 13.9317 16.986H2.33051C1.55853 16.9851 0.933114 16.3597 0.932203 15.5877V6.38568C0.933114 5.61389 1.55853 4.98829 2.33051 4.98738H13.9321C14.7039 4.98829 15.3295 5.61389 15.3304 6.38568L15.33 15.5877ZM21.0678 15.5255L16.2626 12.9018V9.10145L21.0678 6.47799V15.5255Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCamera",
};
</script>

<style scoped lang="scss"></style>
