var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress-block" },
    [
      _c(
        "content-wrapper-block",
        { attrs: { "no-overflow": "" } },
        [
          !_vm.asBlock ? _c("bread-crumbs", { attrs: { dark: "" } }) : _vm._e(),
          _c("div", { staticClass: "progress-block__header" }, [
            _c("div", { staticClass: "progress-block__title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
            _c(
              "div",
              {
                staticClass: "progress-block__header-container progress-select",
              },
              [
                _c(
                  "div",
                  { staticClass: "progress-select__content" },
                  [
                    _vm.lineLeft
                      ? _c("div", { staticClass: "line-left" })
                      : _vm._e(),
                    _c("slider-navigation-arrows", {
                      staticClass: "progress-select__nav",
                      attrs: {
                        "color-arrow": "#E14313",
                        "active-next": _vm.svipeNext,
                        "active-prev": _vm.svipePrev,
                        "disable-prev": _vm.disableButtonPrev,
                        "disable-next": _vm.disablButtonNext,
                      },
                      on: { next: _vm.goToNext, prev: _vm.goToPrev },
                    }),
                    _vm.lineRight
                      ? _c("div", { staticClass: "line-right" })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._t("rightBlock"),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "progress-block__body progress-slider" }, [
            _c(
              "div",
              { staticClass: "progress-slider__images" },
              [
                _c("div", { staticClass: "progress-slider__counter" }),
                _vm.draw
                  ? _c(
                      "swiper",
                      {
                        ref: "swiper",
                        staticClass: "progress-slider__swiper",
                        attrs: {
                          options: {
                            slidesPerView: "auto",
                            spaceBetween: 20,
                            breakpoints: {
                              0: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                              },
                              640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              992: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                              },
                            },
                          },
                        },
                      },
                      _vm._l(_vm.building, function (item, index) {
                        return _c(
                          "swiper-slide",
                          { key: index, staticClass: "progress-slider__slide" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "progress-slider__slide-link",
                                attrs: {
                                  id: item.id,
                                  to: {
                                    name: "InnerStepPage",
                                    params: { slug: item.slug },
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "progress-slider__slide-container",
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "progress-slider__slide-image",
                                      attrs: {
                                        src: item.preview
                                          ? item.preview.image_thumb
                                          : "",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "progress-slider__slide-date",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatTimestamp(item.published_at)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "progress-block__footer" }, [
            _c(
              "div",
              { staticClass: "progress-block__content progress-percent" },
              _vm._l(_vm.buildingPercents, function (item, index) {
                return _c("percent-line", {
                  key: index,
                  attrs: {
                    title: item.progress_name,
                    percent: item.progress_percent,
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }