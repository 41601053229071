var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "percent-block", style: _vm.cssProps }, [
    _c("div", { staticClass: "percent-block__title" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("div", { staticClass: "percent-block__line" }, [
      _vm._v(_vm._s(_vm.percent) + "%"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }