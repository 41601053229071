<template>
  <div class="progress-block">
    <content-wrapper-block no-overflow>
      <bread-crumbs v-if="!asBlock" dark />
      <div class="progress-block__header">
        <div class="progress-block__title">
          {{ title }}
        </div>
        <div class="progress-block__header-container progress-select">
          <div class="progress-select__content">
            <!-- <router-link :to="{ name: 'OnlineBroadcastPage' }">
              <ui-button class="progress-select__button">
                <icon-camera />
              </ui-button>
            </router-link> -->
            <div v-if="lineLeft" class="line-left" />
            <slider-navigation-arrows
              class="progress-select__nav"
              color-arrow="#E14313"
              :active-next="svipeNext"
              :active-prev="svipePrev"
              :disable-prev="disableButtonPrev"
              :disable-next="disablButtonNext"
              @next="goToNext"
              @prev="goToPrev"
            />
            <div v-if="lineRight" class="line-right" />
          </div>
          <slot name="rightBlock" />
        </div>
      </div>

      <div class="progress-block__body progress-slider">
        <div class="progress-slider__images">
          <div class="progress-slider__counter" />
          <swiper
            v-if="draw"
            ref="swiper"
            class="progress-slider__swiper"
            :options="{
              slidesPerView: 'auto',
              spaceBetween: 20,
              breakpoints: {
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              },
            }"
          >
            <swiper-slide
              v-for="(item, index) in building"
              :key="index"
              class="progress-slider__slide"
            >
              <router-link
                :id="item.id"
                class="progress-slider__slide-link"
                :to="{
                  name: 'InnerStepPage',
                  params: { slug: item.slug },
                }"
              >
                <div class="progress-slider__slide-container">
                  <img
                    class="progress-slider__slide-image"
                    :src="item.preview ? item.preview.image_thumb : ''"
                  />
                </div>
                <div class="progress-slider__slide-date">
                  {{ formatTimestamp(item.published_at) }}
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="progress-block__footer">
        <div class="progress-block__content progress-percent">
          <percent-line
            v-for="(item, index) in buildingPercents"
            :key="index"
            :title="item.progress_name"
            :percent="item.progress_percent"
          />
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import PercentLine from "@/components/elements/PercentLine";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import UiButton from "@/components/ui/UIButton";
import IconCamera from "@/components/icons/IconCamera";
import SliderNavigationArrows from "@/components/ui/SliderNavigationArrows";
import BreadCrumbs from "@/components/elements/BreadCrumbs";
import { mapState } from "vuex";

export default {
  name: "ProgressBlock",
  components: {
    BreadCrumbs,
    PercentLine,
    ContentWrapperBlock,
    UiButton,
    IconCamera,
    SliderNavigationArrows,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    asBlock: {
      type: Boolean,
      default: false,
    },
    building: Array,
    initialTab: String,
    lineLeft: {
      type: Boolean,
      default: false,
    },
    lineRight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    draw: true,
    activeTab: "",
    activeSlide: 0,
    activeLastSlide: 0,
    checkSlidePer: 0,
    selected: "",
    svipeNext: false,
    svipePrev: false,
    disableButtonPrev: true,
    disablButtonNext: false,
  }),
  computed: {
    ...mapState({
      buildingPercent: (state) => state.buildingPercent,
    }),

    buildingPercents() {
      const res = [];
      this.buildingPercent.forEach((item) => {
        if (item.progress_active === true) {
          res.push(item);
        }
      });
      return res;
    },

    options() {
      const dates = [];
      this.building.forEach((element) => {
        const monthDate = this.formatMonthTimestamp(element.published_at);
        const monthTextDate = this.formatMonthTextTimestamp(
          element.published_at
        );
        if (!dates.some((item) => item.code === monthDate)) {
          dates.push({
            code: monthDate,
            label: monthTextDate,
          });
        }
      });
      return dates;
    },
  },
  watch: {
    activeTab() {
      // this.draw = false

      this.$nextTick(() => {
        if (this.$refs.swiper) {
          this.$refs.swiper.$swiper.slideTo(0);
        }
      });
    },
    options() {
      this.selected = this.options[0];
    },
    building() {
      this.setSelected(this.selected);
    },
  },
  created() {
    this.selected = this.options[0];
    this.setSelected(this.selected);

    this.$nextTick(() => {
      this.$refs.swiper.$swiper.on("slideNextTransitionStart", () => {
        this.disableButtonPrev = false;
      });
      this.$refs.swiper.$swiper.on("slidePrevTransitionStart", () => {
        this.disablButtonNext = false;
      });
      this.$refs.swiper.$swiper.on("slideNextTransitionStart", () => {
        this.svipeNext = true;
        this.svipePrev = false;
      });
      this.$refs.swiper.$swiper.on("slidePrevTransitionStart", () => {
        this.svipeNext = false;
        this.svipePrev = true;
      });
      this.$refs.swiper.$swiper.on("reachEnd", () => {
        this.disablButtonNext = true;
        this.disableButtonPrev = false;
      });
      this.$refs.swiper.$swiper.on("reachBeginning", () => {
        this.disablButtonNext = false;
        this.disableButtonPrev = true;
      });
    });
  },
  methods: {
    goToNext() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slideNext();
    },
    goToPrev() {
      this.$refs.swiper.$swiper.updateSlides();
      this.$refs.swiper.$swiper.slidePrev();
    },
    setSelected(value) {
      if (this.$refs.swiper) {
        this.$refs.swiper.$swiper.slideTo(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-block {
  overflow: hidden;
  &__header {
    display: block;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 16px;

    @media screen and (min-width: $screen-sm) {
      display: flex;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media screen and (min-width: $screen-sm) {
        flex-direction: column-reverse;
        width: 100%;
      }

      @media screen and (min-width: $screen-md) {
        align-items: center;
        flex-direction: row;
        width: calc(100% - 404px);
      }
    }

    @media screen and (min-width: $screen-md) {
      align-items: center;
      flex-direction: row;
      margin-bottom: 38px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05em;
    color: #18181b;
    font-size: 30px;
    line-height: 105%;
    white-space: nowrap;

    @media screen and (min-width: $screen-sm) {
      align-items: flex-start;
    }
    @media screen and (min-width: $screen-md) {
      font-size: 48px;
      line-height: 130%;
      margin-right: 50px;
      margin-bottom: 0;
    }
  }
  &__content {
    display: flex;
    align-items: center;
  }
}
.progress-percent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  flex-direction: column;

  @media screen and (min-width: $screen-sm) {
    flex-direction: row;
    margin: 0 -15px;
  }
}
.progress-select {
  &__button {
    display: flex;
    align-items: center;
    padding: 9px;
    box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);
  }
  &__nav {
    margin-left: auto;
  }
  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: $screen-sm) {
      margin-left: 20px;
      margin-left: 0;
    }

    .slider-navigation {
      display: none;

      @media screen and (min-width: $screen-sm) {
        display: flex;
      }
    }

    .line-right {
      display: none;
      position: absolute;
      right: 0px;
      width: 2px;
      height: 40px;
      background: rgba(24, 24, 27, 0.1);

      @media screen and (min-width: $screen-md) {
        display: block;
      }
    }
    .line-left {
      position: absolute;
      display: none;
      right: 150px;
      width: 1px;
      height: 40px;
      background: #e14313;

      @media screen and (min-width: $screen-sm) {
        display: block;
      }
    }
  }
  &__input {
    max-width: 243px;
    width: 100%;
    margin-left: 10px;
    height: 43px;

    .vs__dropdown-toggle {
      height: 100%;
    }
  }
}
.progress-slider {
  margin-bottom: 25px;

  &__slide {
    &-container {
      padding-bottom: 56.25%;
      position: relative;
      overflow: hidden;
    }
    &-link {
      text-decoration: none;
      transition: opacity 0.3s;

      &:hover {
        transition: 0.3s;
        .progress-slider__slide-image {
          opacity: 0.8;
        }
        .progress-slider__slide-date {
          transition: 0.3s;
          &::before {
            width: 10px;
            height: 10px;
            background: #e14313;
            top: -2px;
            margin: auto;
            border: 2px solid #fff;
            box-shadow: 0 0 0 1px rgba(255, 67, 19, 0.3);
          }
        }
      }
    }
    &-image {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #f4f4f4;
    }
    &-date {
      font-size: 14px;
      line-height: 115%;
      text-align: center;
      letter-spacing: 0.05em;
      color: #18181b;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      margin-top: 24px;
      padding-top: 22px;
      transition: 0.3s;

      &::before {
        content: " ";
        width: 4px;
        height: 4px;
        background: #000000;
        top: 3px;
        position: absolute;
        display: flex;
        border-radius: 50%;
      }
      &::after {
        content: " ";
        height: 10px;
        background: rgba(0, 0, 0, 0.05);
        position: absolute;
        display: flex;
        width: calc(100% + 20px);
        margin-bottom: 12px;
        top: 0;
        left: 0;
      }
    }
  }
  &__images {
    position: relative;
  }
  &__counter {
    color: #fff;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (min-width: 1300px) {
      left: -70px;
    }

    &::before {
      content: " ";
      height: 100%;
      width: 1000px;
      right: 0;
      position: absolute;
      background: #000;
      display: block;
    }

    .slider-counter {
      padding: 12px 16px 12px 27px;
    }
    .slider-navigation {
      z-index: 2;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      border-left: 0;
      margin-right: 16px;
      display: none;

      @media screen and (min-width: $screen-sm) {
        display: flex;
      }
    }
  }
  &__swiper {
    overflow: hidden;

    @media screen and (min-width: $screen-sm) {
      overflow: visible;
    }
  }
}
</style>

<style lang="scss">
.progress-select {
  &__input {
    cursor: pointer;
    box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);

    .multiselect__content-wrapper {
      background: #f9f9f9;
      box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 0;

      font-family: "Rubik", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 115%;
      color: #18181b;
      text-transform: capitalize;
      margin-top: 4px;
    }
  }
  .multiselect__select {
    height: 43px;
  }
  .multiselect__tags {
    text-transform: capitalize;
    height: 43px;
    min-height: 43px;
    display: flex;
    align-items: center;
    padding-top: 0;
  }
  .multiselect__option {
    text-transform: capitalize;
    font-size: 14px;
  }
  .multiselect__single {
    margin-bottom: 0;
    font-size: 14px !important;
    color: #18181b;
  }
  .multiselect__placeholder {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 115%;
    color: #cbd0dd;
    margin-bottom: 0;
  }
}
</style>
