<template>
  <div class="percent-block" :style="cssProps">
    <div class="percent-block__title">
      {{ title }}
    </div>
    <div class="percent-block__line">{{ percent }}%</div>
  </div>
</template>

<script>
export default {
  name: "PercentLine",
  props: {
    title: {
      type: String,
      default: "",
    },
    percent: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    cssProps() {
      return {
        "--percent-width": this.percent + "%",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.percent-block {
  width: 100%;
  margin: auto auto 13px auto;

  @media screen and (min-width: $screen-sm) {
    margin: 10px;
  }
  @media screen and (min-width: $screen-md) {
    margin: 15px;
  }

  &__title {
    font-size: 10px;
    line-height: 132.5%;
    letter-spacing: 0.18em;
    color: #18181b;
    margin-bottom: 7px;

    @media screen and (min-width: $screen-sm) {
      font-size: 12px;
      margin-bottom: 10px;
    }
    @media screen and (min-width: $screen-md) {
      font-size: 16px;
      line-height: 115%;
      letter-spacing: 0.05em;
      color: #18181b;
      margin-bottom: 17px;
    }
  }
  &__line {
    background: rgba(0, 0, 0, 0.1);
    font-size: 12px;
    height: 15px;
    display: flex;
    align-items: center;
    padding-left: 9px;
    position: relative;

    @media screen and (min-width: $screen-sm) {
      font-size: 16px;
      height: 20px;
    }

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: var(--percent-width);
      left: 0;
      background: rgba(24, 24, 27, 0.2);
    }
  }
}
</style>
